export default [
  { path: '/', redirect: '/main' },
  { path: '/main', component: 'main' },
  { path: '/test', component: 'test' },
  { path: '/test1', component: 'test1' },
  {
    path: '/invite',
    component: 'invite',
    title: {
      en: 'Come and join the fun!',
      'zh-CN': '快来一起抓马吧',
      'zh-TW': '快來一起抓馬吧',
    },
  },
  {
    path: '/inviteGroup',
    component: 'inviteGroup',
    title: {
      en: 'Come and join the fun!',
      'zh-CN': '快来一起抓马吧',
      'zh-TW': '快來一起抓馬吧',
    },
  },
  {
    path: '/inviteFriend',
    component: 'inviteFriend',
    title: {
      en: `Come and live in the same apartment with me and let's have a party together!`,
      'zh-CN': '快来跟我住在同一栋公寓一起开party!',
      'zh-TW': '快來跟我住在同一棟公寓，一起開 party！',
    },
  },
  { path: '/intro', component: 'intro' },
  { path: '/intro-mi', component: 'intro-mi' },
  { path: '/intro-other', component: 'intro-other' },
  { path: '/protocolList', component: 'protocolList' },
  { path: '/userProtocol', component: 'userProtocol' },
  { path: '/userProtocol-android', component: 'userProtocolAndroid' },
  { path: '/privacyPolicy', component: 'privacyPolicy' },
  { path: '/privacyPolicy-android', component: 'privacyPolicyAndroid' },
  { path: '/subscribeProtocol', component: 'subscribeProtocol' },
  { path: '/subscribeProtocol-android', component: 'subscribeProtocolAndroid' },
  { path: '/purchaseProtocol', component: 'purchaseProtocol' },
  { path: '/purchaseProtocol-android', component: 'purchaseProtocolAndroid' },
  {
    path: '/inviteRule',
    component: 'inviteRule',
    title: {
      en: 'Event Rules',
      'zh-CN': '活动规则',
      'zh-TW': '活動規則',
    },
  },
  {
    path: '/520activity',
    component: '520activity',
    title: {
      en: '520抓马表白',
      'zh-CN': '520抓马表白',
      'zh-TW': '520抓马表白',
    },
  },
  {
    path: '/xcactivity',
    component: 'xcactivity',
    title: {
      en: '抓马限时活动',
      'zh-CN': '抓马限时活动',
      'zh-TW': '抓马限时活动',
    },
  },
  {
    path: '/xcwxactivity',
    component: 'xcwxactivity',
    title: {
      en: '抓马旅程尽在携程',
      'zh-CN': '抓马旅程尽在携程',
      'zh-TW': '抓马旅程尽在携程',
    },
  },
  {
    path: '/520resultActivity',
    component: '520resultActivity',
    title: {
      en: '520真情告白',
      'zh-CN': '520真情告白',
      'zh-TW': '520真情告白',
    },
  },
  {
    path: '/newFeature',
    component: 'newFeature',
    title: {
      en: '新功能上线',
      'zh-CN': '新功能上线',
      'zh-TW': '新功能上线',
    },
  },
  { path: '/*', component: '404' },
];